import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
    component: () => import('@/views/Admin/Index.vue'),
    children: [
      { 
        path: '/admin',
        name: 'AdminMain',
        meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
        component: () => import('@/views/Admin/Main.vue'),
        redirect: { name: 'AdminInbox' },
        children: [
          {
            path: 'inbox',
            name: 'AdminInbox',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/Inbox/List.vue')
          },
          {
            path: 'inbox/:id',
            name: 'AdminReadMail',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/Inbox/ReadMail.vue')
          },
          { 
            path: 'about',
            name: 'AdminAbout',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/About.vue')
          },
          { 
            path: 'timeline',
            name: 'AdminTimeline',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/View.vue'),
            redirect: { name: 'AdminTimelineList' },
            children: [
              {
                path: '',
                name: 'AdminTimelineList',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                component: () => import('@/views/Admin/Timeline/List.vue'),
              },
              {
                path: 'edit',
                name: 'AdminTimelineEdit',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                props: true,
                component: () => import('@/views/Admin/Timeline/Edit.vue'),
              }
            ]
          },
          { 
            path: 'works',
            name: 'AdminWorks',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/View.vue'),
            redirect: { name: 'AdminWorksList' },
            children: [
              {
                path: '',
                name: 'AdminWorksList',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                component: () => import('@/views/Admin/Works/List.vue'),
              },
              {
                path: 'edit',
                name: 'AdminWorksEdit',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                props: true,
                component: () => import('@/views/Admin/Works/Edit.vue'),
              }
            ]
          },
          { 
            path: 'services',
            name: 'AdminServices',
            meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
            component: () => import('@/views/Admin/View.vue'),
            redirect: { name: 'AdminServicesList' },
            children: [
              {
                path: '',
                name: 'AdminServicesList',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                component: () => import('@/views/Admin/Services/List.vue'),
              },
              {
                path: 'edit',
                name: 'AdminServicesEdit',
                meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
                props: true,
                component: () => import('@/views/Admin/Services/Edit.vue'),
              }
            ]
          }
        ]
      },
      {
        path: '/login',
        name: 'Login',
        meta: { title: 'ADMIN | Ibrahim El Ouadifi' },
        component: () => import('@/views/Admin/Login.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Ibrahim El Ouadifi' },
    component: () => import('@/views/Home/Index.vue'),
    redirect: { name: 'HomeIndex' },
    children: [
      {
        path: '/',
        name: 'HomeIndex',
        meta: { title: 'Ibrahim El Ouadifi' },
        component: () => import('@/views/Home/Home.vue')
      },
      {
        path: '/work',
        name: 'HomeWork',
        meta: { title: 'WORK | Ibrahim El Ouadifi' },
        props: true,
        component: () => import('@/views/Home/Project.vue')
      },
      {
        path: '/contact',
        name: 'HomeContact',
        meta: { title: 'CONTACT | Ibrahim El Ouadifi' },
        component: () => import('@/views/Home/Contact.vue')
      },
      {
        path: '/:catchAll(.*)',
        meta: { title: '404 | Ibrahim El Ouadifi' },
        component: () => import('@/views/Home/NotFound.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
