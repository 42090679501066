import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { firestorePlugin } from 'vuefire'
import vuelidate from 'vuelidate'
import LottiePlayer from '@lottiefiles/vue-lottie-player'
import('bootswatch/dist/cosmo/bootstrap.css')
import('bootstrap/js/dist/dropdown.js')
import('bootstrap-icons/font/bootstrap-icons.css')
import('font-awesome/css/font-awesome.css')
import('./assets/css/style.css')

Vue.config.productionTip = false

Vue.use(firestorePlugin)
Vue.use(LottiePlayer)
Vue.use(vuelidate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
