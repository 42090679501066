<template>
    <transition name="fade" mode="out-in"><router-view/></transition>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch('getData')
    }
}
</script>