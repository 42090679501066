import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '../firebase'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    infos: {},
    education: [],
    experience: [],
    projects: [],
    services: [],
    menu: false,
    notLoading: false
  },
  mutations: {
    setUser: (state, payload) => state.user = payload,
    setInfos: (state, payload) => state.infos = payload,
    setEducation: (state, payload) => state.education = payload,
    setExperience: (state, payload) => state.experience = payload,
    setProjects: (state, payload) => state.projects = payload,
    setServices: (state, payload) => state.services = payload,
    Menu: (state, payload) => state.menu = payload
  },
  actions: {
    getData: contex => {
        try {
        firebase.firestore()
          .collection("infos")
          .doc('doc')
          .get()
          .then((doc) => {
            contex.commit('setInfos', doc.data());
            contex.state.notLoading = true
          })
          .catch((err) => console.log(err));
  
        firebase.firestore()
          .collection("education_timeline")
          .orderBy("to", "desc")
          .get()
          .then(({ docs }) => {
            contex.commit('setEducation', docs);
          })
          .catch((err) => console.log(err));
  
        firebase.firestore()
          .collection("experience_timeline")
          .orderBy("to", "desc")
          .get()
          .then(({ docs }) => {
            contex.commit('setExperience', docs);
          })
          .catch((err) => console.log(err));

        firebase.firestore()
          .collection("projects")
          .get()
          .then(({ docs }) => {
            contex.commit('setProjects', docs);
          })
          .catch((err) => console.log(err));
        
        firebase.firestore()
          .collection("services")
          .get()
          .then(({ docs }) => {
            contex.commit('setServices', docs);
          })
          .catch((err) => console.log(err));
        } catch (err) {
          console.log(err)
        }
      }
  }
})

store.firebase = firebase;

export default store;